import Navbar from './Navbar';
import Footer from './Footer';
import 'animate.css';
import { useLocation, ScrollRestoration } from 'react-router-dom';
import kamenivo04 from '../resources/Kamenivo 0-4.jpeg';
import kamenivo032 from '../resources/Kamenivo 0-32.jpeg';
import kamenivo063 from '../resources/Kamenivo 0-63.jpeg';
import kamenivo48B from '../resources/Kamenivo 4-8 B.jpeg';
import kamenivo0408 from '../resources/Kamenivo 4-8.jpeg';
import kamenivo0816 from '../resources/Kamenivo 8-16.jpeg';
import kamenivo1632 from '../resources/Kamenivo 16-32.jpeg';
import kamenivo3263 from '../resources/Kamenivo 32-63.jpeg';
import Formular from './Formular';
import { useEffect,useState } from 'react';

function Strkopiesky() {
    const [items, setItems] = useState([]);
    const location = useLocation();
    useEffect(() => {
        handleWhatOrder();
    },[location.pathname]);

    const handleWhatOrder = () => {
        if(location.pathname === '/ponuka/kamenivo'){
            setItems([
                'Frakcia 0/4 Kamenivo',
                'Frakcia 4/8B Kamenivo',
                'Frakcia 4/8 Kamenivo',
                'Frakcia 8/16 Kamenivo',
                'Frakcia 0/32 Kamenivo',
                'Frakcia 16/32 Kamenivo',
                'Frakcia 0/63 Kamenivo',
                'Frakcia 32/63 Kamenivo',
                'Frakcia 63/120 Kamenivo'
            ]);
        }
    };

    return (
        <div>
            <Navbar />
            <div className="text-center mt-48 text-5xl font-extrabold mb-16 text-gray-800">
                Naša <span className="text-red-600">ponuka</span>
            </div>
            <div className="flex flex-wrap max-w-full  justify-center m-auto rounded-2xl gap-2 ">

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={kamenivo04} className="h-full w-full object-cover" alt="ŠTRKOPIESKY" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 0/4
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný ako podkladová vrstva pod exteriérovú dlažbu, zhutňujúca vrstva, na obsypanie vodovodných a kanalizačných potrubí.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>10,50 €</td>
                                    <td>12,60 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={kamenivo48B} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 4/8B
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný ako podkladová vrstva pod exteriérovú dlažbu, zhutňujúca vrstva.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>13,88 €</td>
                                    <td>16,65 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={kamenivo0408} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 4/8
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný ako podkladová vrstva pod exteriérovú dlažbu, zhutňujúca vrstva, výroba betónov s nižšou triedou, drenážne zásypy.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>20,75 €</td>
                                    <td>24,90 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={kamenivo0816} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 8/16
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný ako podkladová vrstva pod exteriérovú dlažbu, zhutňujúca vrstva, výroba betónov s nižšou triedou, drenážne zásypy.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>20,63 €</td>
                                    <td>24,75 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={kamenivo032} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 0/32
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný ako podkladová vrstva pre spevnené plochy s vyšším stupňom zaťaženia, pod exteriérovú dlažbu, zhutňujúca vrstva.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>11,67 €</td>
                                    <td>14,00 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={kamenivo1632} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 16/32
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný ako podkladová vrstva pre spevnené plochy s vyšším stupňom zaťaženia, pod exteriérovú dlažbu, zhutňujúca vrstva, drenážne zásypy.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>21,00 €</td>
                                    <td>25,20 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={kamenivo063} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 0/63
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný ako podkladová vrstva pre spevnené plochy s vyšším stupňom zaťaženia, pod exteriérovú dlažbu, zhutňujúca vrstva, drenážne zásypy.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>11,50 €</td>
                                    <td>13,80 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={kamenivo3263} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                        Kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 32/63
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný ako podkladová vrstva pre spevnené plochy s vyšším stupňom zaťaženia, pod exteriérovú dlažbu, zhutňujúca vrstva, drenážne zásypy.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>16,25 €</td>
                                    <td>19,50 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="relative p-8 w-full sm:w-1/2 md:w-[30%] shadow">
                    <div className="h-96 w-full overflow-hidden relative border-b-2 border-gray-300">
                        <img src={kamenivo3263} className="h-full w-full object-cover" alt="DEKORATÍVNE" />
                        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-2 bg-white w-48 rounded shadow-md text-center">
                           Kamenivo
                        </div>
                    </div>
                    <div className="font-bold text-2xl text-red-600 text-center mt-4">
                        Frakcia 63/120
                    </div>
                    <div>
                        <p className="mt-4 text-center">
                            Vhodný ako podkladová vrstva pre spevnené plochy s vyšším stupňom zaťaženia, pod exteriérovú dlažbu, násyp do pohľadových gabiónov.
                        </p>
                    </div>
                    <div className='p-4'>
                        <table className='w-full'>
                            <thead className='bg-gray-500 text-white'>
                                <tr>
                                    <th></th>
                                    <th>bez DPH</th>
                                    <th>s DPH</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cena za t</td>
                                    <td>16,25 €</td>
                                    <td>19,50 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="border-t border-gray-300 mt-10 pt-4 w-2/3 m-auto">
            </div>
            <Formular napln={items} />
            <Footer />
            <ScrollRestoration />
        </div>
    );
}

export default Strkopiesky;
