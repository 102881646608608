import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import 'animate.css';

function Footer (){
    return (
   <footer className="bg-gray-100 shadow ">
   <div className="max-w-screen-xl h-full mx-auto py-12 px-4 sm:px-6 lg:px-8">
   <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="flex flex-col items-center md:items-start">
                <span className="text-red-600 font-bold text-3xl">Kontakt</span>
                <div className="mt-4 text-gray-700">
                <div className="mt-4 flex items-center">
                <FaMapMarkerAlt className="mr-2" />
                <div>
                  Petromin s.r.o. <br />
                  Prešovská 539 <br />
                  087 01 Giraltovce
                </div>
                </div>
                <div className="flex items-center mt-2">
                <FaPhone className="mr-2" />
                <div>0918 495 001</div>
              </div>
              <div className="flex items-center mt-2">
                <FaPhone className="mr-2" />
                <div>0908 174 155</div>
              </div>
              <div className="flex items-center mt-2">
                <FaEnvelope className="mr-2" />
                <div>petromin@petromin.sk</div>
              </div>
              </div>
              
               </div>
               
               <div className="flex flex-col items-center md:items-start">
                <span className="text-red-600 font-bold text-3xl">Naša ponuka</span>
                <div className="mt-4">
                    Štrkopiesky<br />
                    Piesok a zemina <br />
                    Okrasný kameň <br />
                    Kamenivo
                </div>
               </div>
               <div className="flex flex-col items-center md:items-start">
            <span className="text-red-600 font-bold text-3xl">O firme</span>
            <div className="mt-4 text-gray-700">
              Petromin s.r.o. je spoločnosť, ktorá sa špecializuje na dodávku stavebných materiálov. Ponúkame široký sortiment kvalitných produktov pre vaše projekty.
            </div>
          </div>
               
            </div>

            <div className="border-t border-gray-300 mt-8 pt-4">
          <div className="flex justify-center text-gray-700">
            Petromin s.r.o. © 2024 | Všetky práva vyhradené
          </div>
        </div>
        </div>
            </footer>
    )
}
export default Footer;